.Chart {
    height: 100vh;
    display: flex;
    background-image: url("background.png");
    background-position: center;
    background-size: cover;
    flex-direction: column;
    row-gap: 2em;
    padding: 2rem;
    box-sizing: border-box;
    overflow: auto;
}

.Chart__Container {
    min-width: 256px;
    max-width: 1024px;
    margin: 0 auto;
}

.Chart__Header {

}

.Chart__Header > .Chart__Container {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    row-gap: 1.5em;
}

#Chart__LogoHeader {
    font-size: 3em;
    padding: 0;
    margin: 0;
}

#Chart__TitleHeader {
    text-transform: uppercase;
    font-size: 1.5em;
    margin: 0;
}

.Chart__Content {

}

.Chart__List {
    display: grid;
    row-gap: 1.2em;
    grid-template-columns: min-content 1fr min-content;
    max-width: max-content;
    align-items: center;
    column-gap: 1em;
}

.Chart__List > tr {
    display: contents;
}

.Chart__Position {

}

.Chart__Footer {

}

.Chart__Item {
    display: flex;
    flex-direction: row;
    column-gap: 2em;
    align-items: center;
}

.Chart__Item__Place {
    border-radius: 50%;
    display: flex;
    height: 1.8em;
    width: 1.8em;
    font-size: 1.4em;
    color: #0000005e;
    align-items: center;
    justify-content: center;
    background-color: #00000008;
    flex-shrink: 0;
}

.Chart__Item__Place--Gold {
    box-shadow: 0.075em 0.075em #c4a300, inset 0.025em 0.025em 0.1em #00000030;
    border: 0.1em solid #ffffff52;
    font-weight: bold;
    font-size: 1.5rem;
    box-sizing: border-box;
    color: #fff2a4;
    background-color: #ffc400;
}

.Chart__Item__Place--Bronze {
    box-shadow: 0.075em 0.075em #da8500, inset 0.025em 0.025em 0.1em #00000030;
    border: 0.1em solid #ffffff52;
    font-weight: bold;
    font-size: 1.5rem;
    box-sizing: border-box;
    color: #ffc57f;
    background-color: #e38a3d;
}

.Chart__Item__Place--Silver {
    box-shadow: 0.075em 0.075em #9e9e9e, inset 0.025em 0.025em 0.1em #00000030;
    border: 0.1em solid #ffffff52;
    font-weight: bold;
    font-size: 1.5rem;
    box-sizing: border-box;
    color: #ececec;
    background-color: #c6c6c6;
}

.Chart__Item__Name {
    font-size: 1.3em;
    color: #000000b0;
}

.Chart__Item__Score {
    display: flex;
    align-items: center;
    column-gap: .1em;
    justify-content: flex-end;
}

.Chart__Item__Crown {
    background-image: url("tg_crown.png");
    width: 1.4em;
    height: 1.4em;
    background-size: contain;
}

.Chart__Item__ScoreText {
    font-size: 1em;
}
