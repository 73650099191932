.Splash {
    background-color: #0089ff;
    overflow: hidden;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 0.5em;
}

.Splash--Error {
    background-color: #ff0062;
}

.Splash--Denied {

}

.Splash__Logo {
    width: 80vw;
    height: 12em;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Splash__LogoText{
    font-size: 2em;
    color: white;
}

.Splash__Eggs {
    height: 12em;
    width: 12em;
    background-image: url("eggs.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.Splash__Denied {
    height: 8em;
    width: 8em;
    background-image: url("denied.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    color: red;
}

.Splash__Message {
    font-size: 1em;
    color: white;
}
