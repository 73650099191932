.WebApp {
    background-color: #ebe8e6;
    height: 100vh;
}

.WebApp__Container {
    padding: 3em 2em;
    display: flex;
    row-gap: 1.5em;
    flex-flow: column;
}

.WebApp__Section {
    display: flex;
    flex-flow: column;
    row-gap: 1em;
}

.WebApp__Welcome {
    font-size: 2em;
}

.WebApp__Subtitle {
    font-size: 1.3em;
}

.WebApp__PaymentsContainer {

}

.WebApp__PaymentsList {
    display: flex;
    flex-flow: column;
    row-gap: 0.8em;
}
