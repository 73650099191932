.UnderConstruction {
    background-color: #ffeb3b;
    display: grid;
    height: 100vh;
    align-content: center;
}

.UnderConstruction__Header {
    height: 8rem;
}

.UnderConstruction__Decoration {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.UnderConstruction__DecorationLine {
    width: 100%;
    height: 2em;
    background-image: url("pattern.png");
    background-size: contain;
}

.UnderConstruction__DecorationText {
    font-size: 2.5em;
    font-weight: 900;
    text-transform: uppercase;
    padding: .1em 0;
}

.UnderConstruction__Content {
    display: flex;
    justify-content: center;
    padding: 2em 0;
}

.UnderConstruction__Content a {
    background-color: #000000FF;
    padding: 0 .2rem;
    display: inline-block;
    color: #ffeb3b;
    text-decoration: none;
}

.UnderConstruction__Content a:hover {
    background-color: #000000aa;
    color: #ffeb3b;
}
